export const tagRoutes =
[
	{
		path: 'product',
		loadComponent: () => import('./product/product.component').then(c => c.ResourceBlogTagProductComponent)
	},
	{
		path: 'calculators',
		loadComponent: () => import('./calculators/calculators.component').then(c => c.ResourceBlogTagCalculatorsComponent)
	},
	{
		path: 'guides',
		loadComponent: () => import('./guides/guides.component').then(c => c.ResourceBlogTagGuidesComponent)
	}
];
