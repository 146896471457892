export const octoberRoutes =
[
	{
		path: 'total-daily-energy-expenditure',
		loadComponent: () => import('./total-daily-energy-expenditure/total-daily-energy-expenditure.component').then(c => c.ResourceBlogTotalDailyEnergyExpenditureComponent)
	},
	{
		path: 'macronutrient',
		loadComponent: () => import('./macro-nutrient/macro-nutrient.component').then(c => c.ResourceBlogMacroNutrientComponent)
	},
	{
		path: 'calorie-surplus-deficit',
		loadComponent: () => import('./calorie-surplus-deficit/calorie-surplus-deficit.component').then(c => c.ResourceBlogCalorieSurplusDeficitComponent)
	},
	{
		path: 'ideal-weight',
		loadComponent: () => import('./ideal-weight/ideal-weight.component').then(c => c.ResourceBlogIdealWeightComponent)
	},
	{
		path: 'calories-burned',
		loadComponent: () => import('./calories-burned/calories-burned.component').then(c => c.ResourceBlogCaloriesBurnedComponent)
	},
	{
		path: 'heart-rate-zone',
		loadComponent: () => import('./heart-rate-zone/heart-rate-zone.component').then(c => c.ResourceBlogHeartRateZoneComponent)
	},
//	{
//		path: 'one-rep-max',
//		loadComponent: () => import('./one-rep-max/one-rep-max').then(c => c.ResourceBlogOneRepMaxComponent)
//	},
//	{
//		path: 'water-intake',
//		loadComponent: () => import('./water-intake/water-intake').then(c => c.ResourceBlogWaterIntakeComponent)
//	},
//	{
//		path: 'waist-to-hip-ratio',
//		loadComponent: () => import('./waist-to-hip-ratio/waist-to-hip-ratio').then(c => c.ResourceBlogWaistToHipRatioComponent)
//	},
//	{
//		path: 'votwo-max',
//		loadComponent: () => import('./votwo-max/votwo-max.component').then(c => c.ResourceBlogVotwoMaxComponent)
//	},
//	{
//		path: 'sleep',
//		loadComponent: () => import('./sleep/sleep.component').then(c => c.ResourceBlogSleepComponent)
//	},
//	{
//		path: 'pace-calculator-for-runners',
//		loadComponent: () => import('./pace-calculator-for-runners/pace-calculator-for-runners.component').then(c => c.ResourceBlogPaceCalculatorForRunnersComponent)
//	},
//	{
//		path: 'protein-intake',
//		loadComponent: () => import('./protein-intake/protein-intake.component').then(c => c.ResourceBlogProteinIntakeComponent)
//	},
//	{
//		path: 'step-to-distance-and-calorie-converter',
//		loadComponent: () => import('./step-to-distance-and-calorie-converter/step-to-distance-and-calorie-converter.component').then(c => c.ResourceBlogStepToDistanceAndCalorieConverterComponent)
//	},
//	{
//		path: 'fitness-age',
//		loadComponent: () => import('./fitness-age/fitness-age.component').then(c => c.ResourceBlogFitnessAgeComponent)
//	},
//	{
//		path: 'pregnancy-weight-gain',
//		loadComponent: () => import('./pregnancy-weight-gain/pregnancy-weight-gain.component').then(c => c.ResourceBlogPregnancyWeightGainComponent)
//	},
//	{
//		path: 'interval-training-timer',
//		loadComponent: () => import('./interval-training-timer/interval-training-timer.component').then(c => c.ResourceBlogIntervalTrainingTimerComponent)
//	},
//	{
//		path: 'resting-metabolic-rate',
//		loadComponent: () => import('./resting-metabolic-rate/resting-metabolic-rate.component').then(c => c.ResourceBlogRestingMetabolicRateComponent)
//	},
//	{
//		path: 'macro-cycling',
//		loadComponent: () => import('./macro-cycling/macro-cycling.component').then(c => c.ResourceBlogMacroCyclingComponent)
//	},
//	{
//		path: 'nutrient-timing',
//		loadComponent: () => import('./nutrient-timing/nutrient-timing.component').then(c => c.ResourceBlogNutrientTimingComponent)
//	},
//	{
//		path: 'exercise-calorie-deficit',
//		loadComponent: () => import('./exercise-calorie-deficit/exercise-calorie-deficit.component').then(c => c.ResourceBlogExerciseCalorieDeficitComponent)
//	},
//	{
//		path: 'body-measurement-tracker',
//		loadComponent: () => import('./body-measurement-tracker/body-measurement-tracker.component').then(c => c.ResourceBlogBodyMeasurementTrackerComponent)
//	},
];
