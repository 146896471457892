import { aboutUsRoutes } from "./about-us/about-us.routes";

export const companyRoutes =
[
	{
		path: 'about-us',
		children:
		[
			...aboutUsRoutes
		]
	}
];
