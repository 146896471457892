import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { environment } from 'environments/environment';

@Component
(
	{
		selector: 'app-layout-component-footer',
		standalone: true,
		templateUrl: './footer.component.html',
		imports:
		[
			RouterLink
		]
	}
)

export class LayoutFooterComponent
{
	public fitnessCentralAppUrl = environment.fitnessCentralAppUrl;
}
