<!-- Page wrapper -->
<div class="flex flex-col min-h-screen overflow-hidden">
	<app-layout-component-header />

	<!-- Page content -->
	<main class="grow">
		<router-outlet />

		<app-layout-component-footer />
	</main>
</div>
