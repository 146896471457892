export const featuresRoutes =
[
	{
		path: '',
		loadComponent: () => import('./features.component').then(c => c.FeaturesComponent)
	},
	{
		path: 'community',
		loadComponent: () => import('./community/community.component').then(c => c.FeaturesCommunityComponent)
	},
	{
		path: 'training',
		loadComponent: () => import('./training/training.component').then(c => c.FeaturesTrainingComponent)
	},
	{
		path: 'body-composition',
		loadComponent: () => import('./body-composition/body-composition.component').then(c => c.FeaturesBodyCompositionComponent)
	},
	{
		// This is so that the old link will redirect to the right place
		path: 'bodycomposition',
		loadComponent: () => import('./body-composition/body-composition.component').then(c => c.FeaturesBodyCompositionComponent)
	},
	{
		path: 'vitals',
		loadComponent: () => import('./vitals/vitals.component').then(c => c.FeaturesVitalsComponent)
	}
];
