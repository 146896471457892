import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { environment } from 'environments/environment';

@Component
(
	{
		selector: 'app-layout-component-header',
		standalone: true,
		templateUrl: './header.component.html',
		imports:
		[
			CommonModule,
			RouterLink
		]

	}
)

export class LayoutHeaderComponent
{
	public fitnessCentralAppUrl = environment.fitnessCentralAppUrl;

	// Object to store routes for better structure
	public routes =
	{
		home: '/',

		features:
		[
			{ label: 'Overview', url: '/features' },
			{ label: 'Community', url: '/features/community' },
			{ label: 'Training', url: '/features/training' },
			{ label: 'Body Composition', url: '/features/body-composition' },
			{ label: 'Vitals', url: '/features/vitals' }
		],
		pricing: '/pricing',
		resources:
		[
			{ label: 'Blog', url: '/resources/blog' },
			{ label: 'Changelog', url: '/resources/changelog' },
		],
		blog: '/resources/blog',
		aboutUs: '/company/about-us',
		calculators:
		[
			{ label: 'Overview', url: '/calculator' },
			{ label: 'BMI - Body Mass Index', url: '/calculator/body-mass-index' },
			{ label: '3 Site Skinfold', url: '/calculator/three-site-skinfold' },
			{ label: '7 Site Skinfold', url: '/calculator/seven-site-skinfold' },
			{ label: 'BMR - Basal Metabolic Rate', url: '/calculator/basal-metabolic-rate' },
			{ label: 'TDEE - Energy Expenditure', url: '/calculator/total-daily-energy-expenditure' },
			{ label: 'Macronutrient', url: '/calculator/macronutrient' },
			{ label: 'Calorie Deficit/Surplus', url: '/calculator/calorie-surplus-deficit' },
			{ label: 'Ideal Weight', url: '/calculator/ideal-weight' },
			{ label: 'Calories Burned', url: '/calculator/calories-burned' },
			{ label: 'Heart Rate Zone', url: '/calculator/heart-rate-zone' },
			{ label: '1 Rep Max (1RM)', url: '/calculator/one-rep-max' },
			{ label: 'Water Intake', url: '/calculator/water-intake' },
			{ label: 'Waist-to-Hip Ratio', url: '/calculator/waist-to-hip-ratio' },
			{ label: 'VO2 Max', url: '/calculator/votwo-max' },
			{ label: 'Sleep Timing', url: '/calculator/sleep' },
			{ label: 'Pace Calculator for Runners', url: '/calculator/pace-calculator-for-runners' },
			{ label: 'Protein Intake', url: '/calculator/protein-intake' },
			{ label: 'Step To Distance And Calorie Converter', url: '/calculator/step-to-distance-and-calorie-converter' },
			{ label: 'Fitness Age', url: '/calculator/fitness-age' },
			{ label: 'Pregnancy Weight Gain', url: '/calculator/pregnancy-weight-gain' },
			{ label: 'Interval Training Timer', url: '/calculator/interval-training-timer' },
			{ label: 'Resting Metabolic Rate', url: '/calculator/resting-metabolic-rate' },
			{ label: 'Macro Cycling', url: '/calculator/macro-cycling' },
		],
		login: 'https://fitnesscentral.io/authentication/signin',
		signup: 'https://fitnesscentral.io/authentication/signup'
	};

    // Menu states
	public mobileMenuOpen: boolean = false;

	public featuresDropdownOpen: boolean = false;
	public resourcesDropdownOpen: boolean = false;
	public calculatorsDropdownOpen: boolean = false;

	public mobileFeaturesDropdownOpen: boolean = false;
	public mobileResourcesDropdownOpen: boolean = false;
	public mobileCalculatorsDropdownOpen: boolean = false;

	public constructor()
	{
	}

	// Generic toggle method for dropdowns (public because it's used in the template)
	public toggleDropdown(dropdown: 'mobileMenuOpen' | 'featuresDropdownOpen' | 'resourcesDropdownOpen' | 'calculatorsDropdownOpen' | 'mobileFeaturesDropdownOpen' | 'mobileResourcesDropdownOpen' | 'mobileCalculatorsDropdownOpen'): void
	{
		this[dropdown] = !this[dropdown];
	}

	// Close mobile menus when selecting a link
	public closeMobileMenu()
	{
		this.mobileMenuOpen = false;
	}
}
