export const vitalRoutes =
[
	{
		path: 'summary',
		loadComponent: () => import('./summary/summary.component').then(c => c.ResourceBlogVitalSummaryComponent)
	},
	{
		path: 'blood-glucose',
		loadComponent: () => import('./blood-glucose/blood-glucose.component').then(c => c.ResourcesBlogArticleSummaryVitalBloodGlucoseComponent)
	},
	{
		path: 'blood-oxygen',
		loadComponent: () => import('./blood-oxygen/blood-oxygen.component').then(c => c.ResourcesBlogArticleSummaryVitalBloodOxygenComponent)
	},
	{
		path: 'blood-pressure',
		loadComponent: () => import('./blood-pressure/blood-pressure.component').then(c => c.ResourcesBlogArticleSummaryVitalBloodPressureComponent)
	},
	{
		path: 'body-temperature',
		loadComponent: () => import('./body-temperature/body-temperature.component').then(c => c.ResourcesBlogArticleSummaryVitalBodyTemperatureComponent)
	},
	{
		path: 'breathing-rate',
		loadComponent: () => import('./breathing-rate/breathing-rate.component').then(c => c.ResourcesBlogArticleSummaryVitalBreathingRateComponent)
	},
	{
		path: 'heart-rate',
		loadComponent: () => import('./heart-rate/heart-rate.component').then(c => c.ResourcesBlogArticleSummaryVitalHeartRateComponent)
	}
];
