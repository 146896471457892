import { blogRoutes } from "./blog/blog.routes";
import { changelogRoutes } from "./changelog/changelog.routes";

export const resourcesRoutes =
[
	{
		path: 'blog',
		children:
		[
			...blogRoutes
		]
	},
	{
		path: 'changelog',
		children:
		[
			...changelogRoutes
		]
	}
];
