export const changelogRoutes =
[
	{
		path: '',
		loadComponent: () => import('./changelog.component').then(c => c.ResourceChangelogComponent)
	},
	{
		path: 'page-2',
		loadComponent: () => import('./02/changelog-page-two.component').then(c => c.ResourceChangelogPageTwoComponent)
	},
	{
		path: 'page-3',
		loadComponent: () => import('./03/changelog-page-three.component').then(c => c.ResourceChangelogPageThreeComponent)
	}
];
