export const calculatorRoutes =
[
	{
		path: '',
		loadComponent: () => import('./calculator.component').then(c => c.CalculatorComponent)
	},
	{
		path: 'body-mass-index',
		loadComponent: () => import('./body-mass-index/body-mass-index.component').then(c => c.CalculatorBodyMassIndexComponent)
	},
	{
		path: 'three-site-skinfold',
		loadComponent: () => import('./three-site-skinfold/three-site-skinfold.component').then(c => c.CalculatorThreeSiteSkinfoldComponent)
	},
	{
		path: 'seven-site-skinfold',
		loadComponent: () => import('./seven-site-skinfold/seven-site-skinfold.component').then(c => c.CalculatorSevenSiteSkinfoldComponent)
	},
	{
		path: 'basal-metabolic-rate',
		loadComponent: () => import('./basal-metabolic-rate/basal-metabolic-rate.component').then(c => c.CalculatorBasalMetabolicRateComponent)
	},
	{
		path: 'total-daily-energy-expenditure',
		loadComponent: () => import('./total-daily-energy-expenditure/total-daily-energy-expenditure.component').then(c => c.CalculatorTotalDailyEnergyExpenditureComponent)
	},
	{
		path: 'macronutrient',
		loadComponent: () => import('./macro-nutrient/macro-nutrient.component').then(c => c.CalculatorMacroNutrientComponent)
	},
	{
		path: 'calorie-surplus-deficit',
		loadComponent: () => import('./calorie-surplus-deficit/calorie-surplus-deficit.component').then(c => c.CalculatorCalorieDeficitSurplusComponent)
	},
	{
		path: 'ideal-weight',
		loadComponent: () => import('./ideal-weight/ideal-weight.component').then(c => c.CalculatorIdealWeightComponent)
	},
	{
		path: 'calories-burned',
		loadComponent: () => import('./calories-burned/calories-burned.component').then(c => c.CalculatorCaloriesBurnedComponent)
	},
	{
		path: 'heart-rate-zone',
		loadComponent: () => import('./heart-rate-zone/heart-rate-zone.component').then(c => c.CalculatorHeartRateZoneComponent)
	},
	{
		path: 'one-rep-max',
		loadComponent: () => import('./one-rep-max/one-rep-max.component').then(c => c.CalculatorOneRepMaxComponent)
	},
	{
		path: 'water-intake',
		loadComponent: () => import('./water-intake/water-intake.component').then(c => c.CalculatorWaterIntakeComponent)
	},
	{
		path: 'waist-to-hip-ratio',
		loadComponent: () => import('./waist-to-hip-ratio/waist-to-hip-ratio.component').then(c => c.CalculatorWaistToHipRatioComponent)
	},
	{
		path: 'votwo-max',
		loadComponent: () => import('./votwo-max/votwo-max.component').then(c => c.CalculatorVoTwoMaxComponent)
	},
	{
		path: 'sleep',
		loadComponent: () => import('./sleep/sleep.component').then(c => c.CalculatorSleepComponent)
	},
	{
		path: 'pace-calculator-for-runners',
		loadComponent: () => import('./pace-calculator-for-runners/pace-calculator-for-runners.component').then(c => c.CalculatorPaceCalculatorForRunnersComponent)
	},
	{
		path: 'protein-intake',
		loadComponent: () => import('./protein-intake/protein-intake.component').then(c => c.CalculatorProteinIntakeComponent)
	},
	{
		path: 'step-to-distance-and-calorie-converter',
		loadComponent: () => import('./step-to-distance-and-calorie-converter/step-to-distance-and-calorie-converter.component').then(c => c.CalculatorStepToDistanceAndCalorieConverterComponent)
	},
	{
		path: 'fitness-age',
		loadComponent: () => import('./fitness-age/fitness-age.component').then(c => c.CalculatorFitnessAgeComponent)
	},
	{
		path: 'pregnancy-weight-gain',
		loadComponent: () => import('./pregnancy-weight-gain/pregnancy-weight-gain.component').then(c => c.CalculatorPregnancyWeightGainComponent)
	},
	{
		path: 'interval-training-timer',
		loadComponent: () => import('./interval-training-timer/interval-training-timer.component').then(c => c.CalculatorIntervalTrainingTimerComponent)
	},
	{
		path: 'resting-metabolic-rate',
		loadComponent: () => import('./resting-metabolic-rate/resting-metabolic-rate.component').then(c => c.CalculatorRestingMetabolicRateComponent)
	},
	{
		path: 'macro-cycling',
		loadComponent: () => import('./macro-cycling/macro-cycling.component').then(c => c.CalculatorMacroCyclingComponent)
	},
];
