import { septemberRoutes } from "./09/09.routes";
import { octoberRoutes } from "./10/10.routes";

export const TwentyTwentyFourRoutes =
[
	{
		path: '09',
		children:
		[
			...septemberRoutes
		]
	},
	{
		path: '10',
		children:
		[
			...octoberRoutes
		]
	}
];
