import { bodyFatRoutes } from './body-fat/body-fat.routes';
import { vitalRoutes } from './vital/vital.routes';

export const septemberRoutes =
[
	{
		path: 'welcome-to-fitness-central',
		loadComponent: () => import('./welcome-to-fitness-central/welcome-to-fitness-central.component').then(c => c.ResourceBlogWelcomeToFitnessCentralComponent)
	},
	{
		path: 'body-mass-index',
		loadComponent: () => import('./body-mass-index/body-mass-index.component').then(c => c.ResourceBlogBodyMassIndexComponent)
	},
	{
		// This is for the redirects. This is the old urls
		path: 'body-fat-summary',
		loadComponent: () => import('./body-fat/summary/summary.component').then(c => c.ResourceBlogBodyFatSummaryComponent)
	},
	{
		path: 'basal-metabolic-rate',
		loadComponent: () => import('./basal-metabolic-rate/basal-metabolic-rate.component').then(c => c.ResourceBlogBasalMetabolicRateComponent)
	},
	{
		path: 'body-fat',
		children:
		[
			...bodyFatRoutes
		]
	},
	{
		path: 'vital',
		children:
		[
			...vitalRoutes
		]
	}
];
