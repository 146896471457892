export const policiesRoutes =
[
	{
		path: 'privacy-policy',
		loadComponent: () => import('./privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent)
	},
	{
		path: 'terms-and-conditions',
		loadComponent: () => import('./terms-and-conditions/terms-and-conditions.component').then(c => c.TermsAndConditionsComponent)
	},
];
