<!-- Site header -->
<header class="absolute w-full z-30">
	<div class="max-w-6xl mx-auto px-4 sm:px-6">
		<div class="flex items-center justify-between h-20">
			<!-- Site branding -->
			<div class="shrink-0 mr-4">
				<a class="block" [routerLink]="routes.home" aria-label="Fitness Central">
					<img class="w-40 fill-current text-purple-600" src="/images/logo/logo-white-text.svg" alt="Fitness Central Logo">
				</a>
			</div>

			<!-- Hamburger Menu Button for Mobile -->
			<div class="lg:hidden">
				<button (click)="toggleDropdown('mobileMenuOpen')" class="text-gray-300 hover:text-gray-200 focus:outline-none">
					<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
					</svg>
				</button>
			</div>

			<!-- Desktop navigation -->
			<nav class="hidden lg:flex grow">
				<ul class="flex grow justify-end flex-wrap items-center">
					<!-- Features Dropdown -->
					<li class="relative" (mouseenter)="toggleDropdown('featuresDropdownOpen')" (mouseleave)="toggleDropdown('featuresDropdownOpen')">
						<button class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out cursor-pointer">
							Features
							<svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
							</svg>
						</button>

						<!-- Dropdown Menu -->
						@if (featuresDropdownOpen)
						{
							<ul class="absolute top-full left-0 w-48 bg-gray-800 text-gray-300 shadow-lg rounded-lg z-50">
								@for (feature of routes.features; track $index)
								{
									<li>
										<a class="block px-4 py-2 hover:bg-gray-700" [routerLink]="feature.url">{{ feature.label }}</a>
									</li>
								}
							</ul>
						}
					</li>

					<!-- Other menu items -->
					<li><a class="text-gray-300 hover:text-gray-200 px-4 py-2" [routerLink]="routes.pricing">Pricing</a></li>

					<!-- Resources Dropdown -->
					<li class="relative" (mouseenter)="toggleDropdown('resourcesDropdownOpen')" (mouseleave)="toggleDropdown('resourcesDropdownOpen')">
						<button class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out cursor-pointer">
							Resources
							<svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
							</svg>
						</button>
						<!-- Dropdown Menu -->
						@if (resourcesDropdownOpen)
						{
							<ul class="absolute top-full left-0 w-48 bg-gray-800 text-gray-300 shadow-lg rounded-lg z-50">
								@for (resource of routes.resources; track $index)
								{
									<li>
										<a class="block px-4 py-2 hover:bg-gray-700" [routerLink]="resource.url">{{ resource.label }}</a>
									</li>
								}
							</ul>
						}
					</li>

					<li><a class="text-gray-300 hover:text-gray-200 px-4 py-2" [routerLink]="routes.aboutUs">About us</a></li>

					<!-- Calculators Dropdown -->
					<li class="relative" (mouseenter)="toggleDropdown('calculatorsDropdownOpen')" (mouseleave)="toggleDropdown('calculatorsDropdownOpen')">
						<button class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out cursor-pointer">
							Calculators
							<svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
							</svg>
						</button>
						<!-- Dropdown Menu -->
						@if (calculatorsDropdownOpen)
						{
							<ul class="absolute top-full left-0 w-48 bg-gray-800 text-gray-300 shadow-lg rounded-lg z-50">
								@for (calculator of routes.calculators; track $index)
								{
									<li>
										<a class="block px-4 py-2 hover:bg-gray-700" [routerLink]="calculator.url">{{ calculator.label }}</a>
									</li>
								}
							</ul>
						}
					</li>
				</ul>

				<!-- Desktop sign-in links -->
				<ul class="flex grow justify-end flex-wrap items-center">
					<li><a class="text-gray-300 hover:text-gray-200 px-4 py-2" [href]="routes.login" target="_blank">Log in</a></li>
					<li><a class="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3" [href]="routes.signup" target="_blank">Sign up</a></li>
				</ul>
			</nav>

			<!-- Mobile navigation menu -->
			<div class="lg:hidden absolute top-20 inset-x-0 bg-gray-900 text-gray-300" [ngClass]="{'block': mobileMenuOpen, 'hidden': !mobileMenuOpen}">
				<nav>
					<ul class="flex flex-col items-center">
						<!-- Mobile Features Dropdown -->
						<li class="relative">
							<button (click)="toggleDropdown('mobileFeaturesDropdownOpen')" class="block px-4 py-2">
								Features
								<svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
								</svg>
							</button>
							@if (mobileFeaturesDropdownOpen)
							{
								<ul class="absolute top-full left-0 w-48 bg-gray-800 text-gray-300 shadow-lg rounded-lg z-50">
									@for (feature of routes.features; track $index)
									{
										<li>
											<a class="block px-4 py-2 hover:bg-gray-700" [routerLink]="feature.url">{{ feature.label }}</a>
										</li>
									}
								</ul>
							}
						</li>

						<li><a class="block px-4 py-2" [routerLink]="routes.pricing" (click)="closeMobileMenu()">Pricing</a></li>

						<!-- Mobile Resources Dropdown -->
						<li class="relative">
							<button (click)="toggleDropdown('mobileResourcesDropdownOpen')" class="block px-4 py-2">
								Resources
								<svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
								</svg>
							</button>
							<!-- Dropdown Menu -->
							@if (mobileResourcesDropdownOpen)
							{
								<ul class="absolute top-full left-0 w-48 bg-gray-800 text-gray-300 shadow-lg rounded-lg z-50">
									@for (resource of routes.resources; track $index)
									{
										<li>
											<a class="block px-4 py-2 hover:bg-gray-700" [routerLink]="resource.url">{{ resource.label }}</a>
										</li>
									}
								</ul>
							}
						</li>

						<li><a class="block px-4 py-2" [routerLink]="routes.aboutUs" (click)="closeMobileMenu()">About us</a></li>

						<!-- Calculators Dropdown -->
						<li class="relative">
							<button (click)="toggleDropdown('mobileCalculatorsDropdownOpen')" class="block px-4 py-2">
								Calculators
								<svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
								</svg>
							</button>
							<!-- Dropdown Menu -->
							@if (mobileCalculatorsDropdownOpen)
							{
								<ul class="absolute top-full left-0 w-48 bg-gray-800 text-gray-300 shadow-lg rounded-lg z-50">
									@for (calculator of routes.calculators; track $index)
									{
										<li>
											<a class="block px-4 py-2 hover:bg-gray-700" [routerLink]="calculator.url">{{ calculator.label }}</a>
										</li>
									}
								</ul>
							}
						</li>

						<li><a class="block px-4 py-2" [href]="routes.login" target="_blank" (click)="closeMobileMenu()">Log in</a></li>
						<li><a class="block px-4 py-2 bg-purple-600 text-white rounded" [href]="routes.signup" target="_blank" (click)="closeMobileMenu()">Sign up</a></li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</header>
