import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LayoutFooterComponent } from './_components/footer/footer.component';
import { LayoutHeaderComponent } from './_components/header/header.component';

@Component
(
	{
		selector: 'app-layout',
		standalone: true,
		templateUrl: './layout.component.html',
		imports:
		[
			RouterOutlet,
			LayoutHeaderComponent,
			LayoutFooterComponent
		]
	}
)

export class LayoutComponent
{

}
