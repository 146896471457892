export const bodyFatRoutes =
[
	{
		path: 'summary',
		loadComponent: () => import('./summary/summary.component').then(c => c.ResourceBlogBodyFatSummaryComponent)
	},
	{
		path: 'skinfold-measurement',
		loadComponent: () => import('./skinfold-measurement/skinfold-measurement.component').then(c => c.ResourceBlogBodyFatSkinfoldMeasurementComponent)
	},
	{
		path: 'bioelectrical-impedance-analysis',
		loadComponent: () => import('./bioelectrical-impedance-analysis/bioelectrical-impedance-analysis.component').then(c => c.ResourceBlogBodyFatBioelectricalImpedanceAnalysisComponent)
	},
	{
		path: 'dual-energy-x-ray-absorptiometry',
		loadComponent: () => import('./dual-energy-x-ray-absorptiometry/dual-energy-x-ray-absorptiometry.component').then(c => c.ResourceBlogBodyFatDualEnergyXrayAbsorptiometryDexaComponent)
	},
	{
		path: 'hydrostatic-weighing',
		loadComponent: () => import('./hydrostatic-weighing/hydrostatic-weighing.component').then(c => c.ResourceBlogBodyFatHydrostaticWeighingComponent)
	},
	{
		path: 'air-displacement-plethysmography',
		loadComponent: () => import('./air-displacement-plethysmography/air-displacement-plethysmography.component').then(c => c.ResourceBlogBodyFatAirDisplacementPlethysmographyComponent)
	},
	{
		path: 'infrared-interactance',
		loadComponent: () => import('./infrared-interactance/infrared-interactance.component').then(c => c.ResourceBlogBodyFatInfraredInteractanceComponent)
	},
	{
		path: 'three-d-body-scanner',
		loadComponent: () => import('./three-d-body-scanner/three-d-body-scanner.component').then(c => c.ResourceBlogBodyFatThreedBodyScannerComponent)
	}
];
