import { Routes } from '@angular/router';

import { LayoutComponent } from '@fitness-central-web/layout/layout.component';

import { calculatorRoutes } from './calculator/calculator.routes';
import { companyRoutes } from './company/company.routes';
import { errorRoutes } from './error/error.routes';
import { featuresRoutes } from './features/features.routes';
import { policiesRoutes } from './policies/policy.routes';
import { pricingRoutes } from './pricing/pricing.routes';
import { resourcesRoutes } from './resources/resources.routes';

export const routes: Routes =
[
	{
		path: '',
		component: LayoutComponent, children:
		[
			{
				path: '',
				redirectTo: '/home',
				pathMatch: 'full'
			},
			{
				path: 'home',
				loadComponent: () =>
					import('./home/home.component')
					.then(component => component.HomeComponent)
			},
			{
				path: 'calculator',
				children:
				[
					...calculatorRoutes
				]
			},
			{
				path: 'company',
				children:
				[
					...companyRoutes
				]
			},
			{
				path: 'error',
				children:
				[
					...errorRoutes
				]
			},
			{
				path: 'features',
				children:
				[
					...featuresRoutes
				]
			},
			{
				path: 'policies',
				children:
				[
					...policiesRoutes
				]
			},
			{
				path: 'pricing',
				children:
				[
					...pricingRoutes
				]
			},
			{
				path: 'resources',
				children:
				[
					...resourcesRoutes
				]
			},
			{
				path: '**',
				redirectTo: 'error/404'
			}
		]
	},
];
