import { afterNextRender, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { environment } from 'environments/environment';

declare let gtag: Function;

@Component
(
	{
		selector: 'app-root',
		standalone: true,
		templateUrl: './app.component.html',
		imports:
		[
			RouterOutlet
		],
	}
)

export class AppComponent implements OnInit
{
	public googleAnalytics: string = environment.googleAnalytics;

	public constructor(private router: Router)
	{
		afterNextRender(() =>
		{
			this.router.events.subscribe
			(
				event =>
				{
					if (event instanceof NavigationEnd)
					{
						gtag('js', new Date());
						gtag
						(
							'config',
							environment.googleAnalytics,
							{
								'page_path': event.urlAfterRedirects
							}
						);
					}
				}
			)
		});
	}

	public ngOnInit(): void
	{
	}
}
