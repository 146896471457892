import { ApplicationConfig, provideZoneChangeDetection, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';

import { routes } from './app.routes';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';

export const appConfig: ApplicationConfig =
{
	providers:
	[
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(routes),
		provideServiceWorker
		(
			'ngsw-worker.js',
			{
				enabled: !isDevMode(),
				registrationStrategy: 'registerWhenStable:30000'
			}
		), provideClientHydration
		(
			withEventReplay()
		)
	]
};
