import { TwentyTwentyFourRoutes } from "./2024/2024.routes";
import { tagRoutes } from "./tag/tag.routes";

export const blogRoutes =
[
	{
		path: '',
		loadComponent: () => import('./blog.component').then(c => c.ResourceBlogComponent)
	},
	{
		path: '2024',
		children:
		[
			...TwentyTwentyFourRoutes
		]
	},
	{
		path: 'tag',
		children:
		[
			...tagRoutes
		]
	}
];
